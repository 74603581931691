<template>
  <div>
    <div id="Footer" style='z-index: 1000' v-if="$store.state.group_id != 9">
        <div style="width:300px;"></div>
        <div>
          <small> 2006-{{ new Date().getFullYear() }} All Rights Reserved to SAMCOTEC</small>
           || <small>  Version {{$store.state.Version}}</small>
        </div>
        <div style="display:flex;direction:rtl;">
            <div class="footerShow" v-if="$store.state.licenseType.demoVersion == false" style="margin:0px 20px 0 20px;cursor: pointer;color:#fff;background:red;padding:5px 20px;white-space: nowrap;" :title="lang.updateprofile" v-b-toggle.password_users @click="Update()">
              <small>{{lang.changePassword}} {{user.full_name}}</small>
            </div>
            <change-password ref="changePassword" />

            <div class="footerShow branchStyle" v-if="(branch != '' || financeYear != '') && ($store.state.licenseType.activemenu =='erp' || $store.state.licenseType.activemenu =='erpsam') " style="">
              <div v-if="financeYear != ''" style="padding:3px;"> {{ financeYear }} </div>
              <div v-if="financeYear != '' && branch != ''" style="padding:3px;"> | </div>
              <div v-if="branch != ''" style="padding:3px;"> {{ branch }} </div>
            </div>
            <div class="logoImg footerShow">
              <img :src="`${publicPath}img/whatsapp-icon.png`" class="whatsIcon" style="cursor:pointer;margin-top:2px;" @click="gotoWhatsApp()"> 
              <div class="hideFooter" style="padding:3px;font-size:.8rem;cursor:pointer;background:black;color:#fff;" @click="gotoWhatsApp()">{{ lang.customer_support }}  </div>
              <div class="timenow">{{ timenow }}</div>
            </div>
        </div>
      <!-- <img id="ecarlogo" :src="`${publicPath}img/small-logo-light.png`" style="width:60px;float:right;margin-right:10px;"> -->
    </div>
    
  </div>
</template>
<script>
import changePassword from '@/components/changePassword.vue'
import axios from 'axios'

export default {
  components: {changePassword},
  data() {
    return {
      publicPath: window.location.origin+process.env.BASE_URL,
      user: {},
      branch: '',
      timenow: '',
    }
  },
  computed: {
    financeYear: function(){
      return localStorage.getItem('financeYear')
    },
    
    lang: function(){
          if(this.$cookies.get(this.$store.state.LangCooki) != null){
              if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
              }else{
                return this.$store.state.lang.ar;
              }
          }
          else if(this.$store.state.deflang == 'en'){
              return this.$store.state.lang.en;
          }else{
              return this.$store.state.lang.ar;
          }
      },
  },
  methods: {
    gotoWhatsApp(){
      window.open('https://wa.me/966565100268','_blank')
    },
    getTimeNow(){
        const date = new Date();
        let d = date.getDate();
        d = d < 10 ? "0"+d : d;
        let m = date.getMonth() + 1;
        m = m < 10 ? "0"+m : m;
        const y = date.getFullYear();
        let h = date.getHours();
        let dm = "AM";
        if(h == 0) h = 12;
        else if(h < 10) h = "0"+h;
        else if(h>12){h = parseInt(h) - parseInt(12);dm = "PM"}
        else if(h == 12) {dm = "PM"}

        let min = date.getMinutes();
        min = min < 10 ? "0"+min : min;
        let sec = date.getSeconds();
        sec = sec < 10 ? "0"+sec : sec;
        h = h < 10 ? "0"+h : h;
        // this.timenow = d+"-"+m+"-"+y+" "+h+":"+min+":"+sec+" "+dm;
        this.timenow = d+"-"+m+"-"+y+" "+h+":"+min+" "+dm;
    },
    Update(){
      // console.log();
    },
    getUsers(){
        const post = new FormData();
        post.append('defBranch',localStorage.getItem('defBranch'));
        post.append('financeYear',localStorage.getItem('financeYear'));
        post.append("type" , 'getUsers');
        post.append("auth",this.$cookies.get(this.$COOKIEPhase));
        post.append('data[userid]','current');
        axios.post(
            this.$SAMCOTEC.r_path, post
        ).then(
            (response) => {
                const res = response.data;
                // // console.log(res);
                this.user = res.results.data.results[0];
                this.branch = res.results.branches[`branch_${this.lang.langname}`]
            }
        )
    },
  },
  created() {
    this.getUsers();
    setInterval(()=>{this.getTimeNow()},1000)
  }
}
</script>
<style>
#Footer{
  direction:ltr;
  position: fixed;
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
  bottom: 0;
  left:0;
  right:0;
  height: 35px;
  background: #e9e9e9;
  border-top:1px solid #ccc;
  padding:5px;
  text-align: center;
}
.logoImg{
  width: 300px;
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
}
.timenow{
  background:#fff;
  padding:3px;
  width:150px;
  border:1px solid #bbb;
  border-radius: 5px;
}
.whatsIcon{
  height:32px;
}
.branchStyle{
  display:flex;
  direction:ltr;
  background:green;
  color:#fff;
  padding-inline:20px;
}
@media only screen and (max-width: 1000px) {
  .whatsIcon{
    height:24px;
  }
  .timenow{
    display:none;
  }
  .logoImg{
    display:block;
  }
}
@media only screen and (max-width: 1100px) {
  .branchStyle{
    padding-inline:10px;
    white-space: nowrap;
  }
  #Footer{
    height: 40px;
  }
  .logoImg{
    width:24px;
    margin-inline:5px;
  }
  .whatsIcon{
    height:24px;
  }
  .timenow, .hideFooter{
    display:none;
  }
  .footerShow{
    font-size:0.6rem;
  }
}
@media only screen and (max-width: 700px) {
  #Footer{
    flex-direction: column;
  }
}
</style>