const enLang = {
    langname: 'en',
    dir: 'ltr',
    ldir: 'rtl',
    algin: 'left',
    lalgin: 'right',
    invoiceno: 'Invoice No.',
    plate_number: 'Plate No.',
    main_menu: 'Main Menu',
    search: 'Search',
    invoices: 'Invoices',
    add_invoice: 'Add invoice',
    invtype: 'Invoice Type',
    customer: 'Customer',
    mobile: 'Mobile',
    plate: 'Plate',
    model: 'Model',
    inv_date: 'Date',
    total: 'Total',
    vat: 'VAT',
    ftotal: 'Total inc. VAT',
    paid: 'Paid',
    remain: 'Remain',
    remain_amount: 'Remianing Amount',
    action: 'Action',
    status: 'Status',
    view: 'View',
    totals: 'Totals',
    close: 'Close',
    customer_name: 'Customer Name',
    customer_type: 'Customer Type',
    company_name: 'Company name',
    comapny_address: 'Company address',
    company_vatid: 'VAT Number',
    car_model: 'Model',
    car_year: 'Year made',
    car_color: 'Color',
    invoice_type: 'Invoice type',
    vat_type: 'VAT type',
    payment_method: 'Payment method',
    saler_name: 'Saler name',
    invoice_date: 'Invoice date',
    invoice_details: 'Invoice details',
    item_code: 'Item code',
    description: 'Description',
    qtty: 'QTTY',
    item_price: 'Item price',
    discount: 'Discount',
    create_invoice: 'Create Invoice',
    cash_payment: 'Cash method',
    span_payment: 'Span method',
    add: 'Add',
    individual_client: 'Individual  ',
    company_clinet: 'Comp. client',
    prices_include_vat: 'Prices include vat',
    prices_exclusive_vat: 'Prices exclusive of vat',
    prices_without_vat: 'Without VAT',
    none_paid_invoice: 'None Paid Invoice',
    paid_invoice: 'Paid Invoice',
    bank_payment: 'Bank Transfer',
    multi_payments: 'Multi payment method',
    required_field: 'This field is required',
    you_can_not_add_empty_invoice: 'Can not add empty invoice',
    company_ifnormation_required: 'Company information required - Name, Address, CR Numebr',
    invoice_added: 'Invoce Added',
    can_not_add_item_withoud_discription: 'Can not add item without description',
    item_qtty_must_be_more_than_zero: 'Can not add item with zero QTY',
    item_price_must_be_more_than_zero: 'Can not add item with zero price',
    print_invoice: 'Print Invoice',
    view_pdf: 'View PDF',
    invoice_return: 'Return Invoice',
    make_payment: 'Make a payment',
    invoice_tme: 'Invoice Time',
    return_date: 'Return Date',
    return_time: 'Return time',
    return_case: 'Return resone',
    total_exclusive_vat: 'Total exclusive vat',
    total_include_vat: 'Total include VAT',
    payments: 'Payments',
    payment_date: 'Payment date',
    payment_time: 'Payment time',
    payment_amount: 'Payment amount',
    payment_cash: 'Payment cash',
    payment_span: 'Payment Span',
    payment_bank: 'Payment Bank',
    return_invoice: 'Return Invoice',
    return_amount: 'Return amount',
    return: 'Return',
    system_modules: 'Modules',
    customers: 'Customers',
    quotations: 'Quotations',
    accounting: 'Accounting',
    envintory: 'Envintory',
    reports: 'Reports',
    settings: 'Settings',
    logout: 'Logout',
    invoice_notes: 'Invoice Notes',
    add_customer: 'Add Customer',
    additional_mobile: 'Additional Number',
    client_statement: 'Statements',
    mobil_10_digits: 'Mobile No. Must be 10 digits',
    customer_added: 'Customer Added sucsessfuly',
    add_quotation: 'Add Quotation',
    quotnumber: 'Quot No.',
    salesman: 'Salesman',
    quot_date: 'Quotation date',
    quotation_details: 'Quot Details',
    delete: 'Delete',
    company_client: 'Company Client',
    can_not_add_empty_row: 'Can not add empty row',
    please_add_company_info: 'Please add company information',
    quotation_added: 'Quotatin added',
    can_not_add_row_without_discription: 'Can not add row without discription',
    can_not_add_row_with_qtty: 'Please add qtty more than 0',
    date: 'Date',
    credit: 'Credit',
    depit: 'Depit',
    new_quotations: 'New quotations',
    invoiced_quots: 'Invoiced Quots',
    canceled_quots: 'Canceled Quots',
    quotation_no: 'Quot No.',
    printQuot: 'Print Quot.',
    cancel_quto: 'Cancel Quot.',
    quot_time: 'Quot Time',
    print: 'Print',
    cash_total: 'Cash Total',
    span_total: 'SPAN total',
    voutcher_no: 'Voutcher No.',
    receive_from: 'Receive From',
    receive_by: 'Receive By',
    cash: 'Cash',
    span: 'SPAN',
    received_voucher: 'Catch Receipt',
    exchange_voucher: 'Exch. voucher',
    employees: 'Employees',
    recurring_expensess: 'Recurring Expensess',
    add_voutchre: 'Add Voutcher',
    voutcher_resone: 'Voutcher Resone',
    emp_name: 'Employee name',
    id_number: 'ID number',
    basic_salary: 'Basic Salary',
    home_allownance: 'Home Allownance',
    trans_allownance: 'Transport Allownance',
    food_allown: 'Food Allownance',
    other_allown: 'Other Allownance',
    goze_perc: 'GOZE',
    percentate: 'Percentage',
    update: 'Update',
    add_employee: 'Add employee',
    add_expenses: 'Add Expenses',
    expense_type: 'Expense Type',
    yearly: 'Yearly',
    midterm: 'Midterm',
    quarterly: 'Quarterly',
    monthly: 'Monthly',
    daily: 'Daily',
    expense_total: 'Expense Total',
    expense_period: 'Expense Period',
    calculation_will_be_done_automatic: 'Calculation values will be done automaticlly',
    office_rent: 'Office Rent',
    add_maint_request: 'Add Job Order',
    cardid: 'Card No.',
    received: 'Received',
    delivered: 'Delivered',
    spare_partes: 'Spare Parts',
    spare_parts: 'Spare Parts',
    main_info: 'Main info',
    received_date: 'Received date',
    delivery_date: 'Delivery date',
    customer_info: 'Customer Info',
    company_info: 'Company Info',
    car_info: 'Car Info',
    received_status: 'Received Status',
    work_description: 'Work Description',
    worker: "Worker",
    work_price: 'Work Price',
    advanced_payment: 'Advanced payment',
    attachement: 'Attatchment',
    choose_file: 'Choose File',
    save: 'Save',
    additional_information: 'Additional Information',
    work_description_required: 'Work Description Required',
    added_sucess: 'Added Success',
    add_user: 'Add user',
    update_user: 'update user',
    users: 'Users',
    user_name: 'Full name',
    login_name: 'Username',
    permission: 'Permissions',
    last_login: 'Last Login',
    save_login_info: 'Save Login Info',
    password: 'Password',
    export_excel: 'Export excel',
    add_purcahse: 'Add purcahse',
    vendor: 'Vendor',
    purchase: 'Purchase',
    inventory: 'Inventory',
    item_name: 'Item name',
    sales_invoices: 'Sales Invocies',
    sales: 'Sales',
    vat_required_return: 'VAT Required (Return)',
    vat_report: 'VAT Report',
    dealy_report: 'Dealy report',
    card_report: 'Card Report',
    sales_report: 'Sales Report',
    return_report: 'Return report',
    percentage: 'Percentage',
    income: 'Income',
    expenses: 'Expenses',
    expense: 'Expense',
    period_expenses: 'Period Expenses',
    closed_by: 'Closed By',
    return_by: 'Return By',
    shortcust_name: 'Shortcut name',
    vat_number: 'VAT Number',
    system_settings: 'System Settings',
    welcometo: 'Welcome',
    view_invoice  : 'View Invoice',
    added_date: 'Added Date',
    added_time: 'Added Time',
    photo_gallary: 'Photo Gallary',
    print_card: 'Print Card',
    car_description: 'Car description',
    invoice_samary: 'Invoice Summary',
    others: 'Others',
    view_card: 'View Card',
    card_closed: 'Card closed',
    please_check_data: 'Please check the data',
    cant_close_card_when_it_not_fully_paid: 'Please first make all remaining payment',
    card_saved: 'Card Saved',
    customerid: 'Customer ID',
    purchase_invoice: 'Purchase Bills',
    billno: 'Bill No',
    vendor_name: 'Vendor Name',
    vendor_mobile: 'Vendor Contact No.',
    vendor_vatids: 'Vendor VAT No.',
    vendor_address: 'Vendor Address',
    type: 'Type',
    bill_type: 'Bill Type',
    bill_detates: 'Bill Details',
    item_unit: 'Item Unit',
    pace: 'Pace',
    view_purchase_bill: 'View Purchase Bill',
    received_amount: 'Received Amount',
    close_card: 'Close Card',
    chose_file: 'Choose your file',
    upload: 'Upload',
    full_name: 'Full name',
    total_amount: 'Total Amount',
    buy_goods: 'Buy goods',
    sallary: 'Sallary',
    buy_devices: 'Buy devices',
    pay_for_bills: 'Pay bills',
    added_successfuly: 'Successfuly Added',
    nationality: 'Nationality',
    percent: 'Percent',
    group_name: 'Group name',
    permissions: 'Permissions',
    administratior: 'Administrator',
    full_permissions: 'Full permissions',
    accountant: 'Accountant',
    accountatn_permissions: 'Full permissions without setting',
    cashier: 'Cashier',
    cashier_permissions: 'Invoice system and generate cards',
    choose_employee: 'Choose employee',
    projectname: 'Project Name',
    inprogress: 'Progress',
    closed: 'Closed',
    not_invoiced: 'Un Billed',
    vendorid: 'Vendor ID',
    paid_in_advanced: 'Advanced Payment',
    paid_in_advanced_amount: 'Amount',
    installment_number: 'Installment #',
    installment_amount: 'Installment Amount',
    mor_than_zero: 'Please add number more than zero',
    emp_reward: 'Rewarded',
    reward_amount: 'Reward Amount',
    reward_resone: 'Reward resone',
    emp_deducts: 'Deducts',
    deducts_amount: 'Deduct Amount',
    deducts_resone: 'Deduct Resone',
    canceled: 'Canceled',
    notpaid: 'Not Paid',
    workstartdate: 'Work Start Date',
    fix_salary: 'Salary Issue',
    card_expenses: 'Maint. Expenses from inventory',
    invoice_expenses: 'Invoice Expenses',
    expenses_summary: 'Expenses Summary',
    general_expenses: 'General Expenses',
    cards_expenses: 'Maintenance',
    invoices_expenses: 'Invoices',
    salaries: 'Salaries',
    add_general_expense: 'Add Expense',
    periodically: 'Periodically',
    payit: 'Pay Now',
    pay_expenses: 'Pay Expenses',
    payment_period: 'Payment Period',
    expense_amount: 'Amount',
    othermethod: "Other methods.", 
    invoices_total: 'Invoice Totals', 
    invoices_remain: 'Invoice Remaining', 
    other_expenses: 'Other Exp.', 
    exp_total: 'Exp. total', 
    exp_vat:'Exp. VAT',
    employees_and_users: 'ُEmpoyees',
    building_no: 'Building No.',
    street_name: 'Street Name',
    branch_number: 'Branch No.',
    district: 'District',
    zipcode: 'Zipcode',
    city:'City',
    address: 'Address',
    update_customer: 'Update Customer',
    customer_updated: 'Customer Updated',
    update_all: 'Update all',
    update_all_notes: 'تعديل الكل : تعديل كل الفواتير او سندات القبض بالبيانات الجديدة',
    curency: 'SAR',
    pos: 'Point of Sale',
    cancel: 'Cancel',
    total_after_discount: 'Total After Discount',
    multi_payment: 'Multi Payment Methods',
    paid_amount: 'Paid Amount',
    category_name: 'Category',
    category_name_ar: 'Name Ar',
    category_name_en: 'Name En',
    category_type: 'Cat. Type',
    category_parent: 'Parent',
    subcat_count: 'Sub cats.',
    products: 'Items',
    add_category: 'Add Category',
    main_cat: 'Main Category',
    sub_cat: 'Sub Category',
    income_report: 'Income Report',
    incomes: 'Incomes',
    amountprice: 'Amount',
    itotals_per_paymethod: 'Income Totals per Payment Method',
    ototals_per_paymethod: 'Outcome Totals per Payment Method',
    account_statement: 'Account Statment',
    balance: 'balance',
    accounts: 'Accounts',
    account_balance_report: 'Balance Report',
    openingbalance: 'Opining Balance',
    transaction: 'Transaction Balance',
    account: 'Account',
    vlaue: 'Value',
    chart_of_account: 'Chart Of Account',
    definition: 'Definitions',
    paytypes: 'Payment Method',
    car_report: 'Car Report',
    amounttotal: 'Amount Total',
    namear: 'Arabic Name',
    nameen: 'English Name',
    classid: 'Account No.',
    add_pay_type:'Add payment Type',
    statment: 'Statement',
    class_name: 'Account name',
    fixed_assets: 'Fixed Assets',
    late: 'Late',
    price: 'Price',
    vendors: 'Vendors',
    invoice_total: 'Invoices Total',
    vat_total: 'VAT Total',
    add_vendor: 'Add vendor',
    update_vendor: 'Update Vendor',
    vendor_updated_successufly: 'Vendor Added/Updated successfuly',
    vendor_name_required: 'Vendor Name Required',
    vendor_mobile_required: 'Vendor Mobile Required',
    finance_jvs: 'J-Voucher',
    jvnom: 'JV #',
    notes: 'Notes',
    addJV: 'Add JV',
    bnl_report: 'PNL Report',
    balance_report: 'Balance Report',
    gozi_report: 'GOZI Report',
    sales_crm: 'Sales',
    exp_management: 'Management Exp.',
    maintenance_cards: 'Maintenance Cards',
    customer_info_required: 'Customer name/mobile required',
    delivery_date_required: 'Delivery Date Required',
    car_info_required: 'Car info Required',
    please_choose_worker: 'Please choose worker',
    job_price_required: 'Job price required',
    customer_addedd: 'Custoemr Added',
    new: 'New',
    view_employee: 'View Employee',
    add_new_account: 'Add new account',
    account_name_ar: 'Arabic name',
    account_name_en: 'English name',
    mainaccount: 'Main Account',
    accoutn_option: 'Account Options',
    update_acount: 'Update Account',
    acount_name: 'Account name',
    account_type: 'Account Type',
    default_type: 'Default Type',
    account_integrated: 'Integrated with',
    integrated_with_expenses: 'Expenses Integrated',
    account_name: 'Account name',
    expeneses_type: 'Expenses type',
    projects: 'Projects',
    add_project: 'Add Project',
    additional_method: 'Additional Discound',
    adding_date: 'Adding Date',
    ammount: 'Amount',
    method: 'Method',
    home: 'Home',
    payment_requests:'Payments',
    requests:'Requests',
    ment_req_cards_new: 'Cards Maint. New',
    ment_req_cards_late: 'Cards Maint. Late',
    ment_req_cards_closed: 'Cards Maint. Closed',
    print_pagexport_pdfe: 'Export PDF',
    print_page: 'Print Page',
    sent_requests: 'Sents Requests',
    item_cost: 'Item Cost',
    username: 'Username',
    welcome: "Welcome",
    add_product: 'Add Product',
    orders: 'Orders',
    booktime: 'Book Time',
    engine_meter: 'Car meter reading (KM)',
    next_oil_change: 'Change oile after (KM)',
    crt_number: 'CR Number',
    company_name_en: 'Company English Name',
    contact_number: 'Contact number',
    you_cannot_delete_this_item_paid_more_than_total: 'You cannot delete this item, total of this card is less than total paid',
    // vin_numebr: 'VIN Number',
    worker_name: 'Worker Name',
    start_date: 'Start date',
    end_date: 'End date',
    total_invoices: 'Total Invoices',
    total_remain: 'Total Remain',
    sendWhats: 'Send WhatsApp',
    salary_report: 'Salary report',
    percent_report: 'Percent report',
    cards_number: 'Cards Number',
    catd_totals: 'Cards Total',
    totalPercent: 'Total Percent',
    cards_expsenses: 'Cards Expenses',
    convert_to_invoice: 'Invoice Quot.',
    chose_month: 'Chose Month',
    year: 'Year',
    logo_img: 'Logo Image',
    terms_of_use: 'Terms of use',
    tafweed: 'Terms of use',
    expense_namear: 'Expenses Type Arabic Name',
    expense_nameen: 'Expenses Type English Name',
    expenses_type_updated: 'expenses type updated',
    add_new_expense_type: 'Add new expense type',
    expenses_type_added: 'expenses type added',
    income_expense_report: 'Income & Expense Report',
    other: 'other',
    expnese: 'Expenses',
    old_balance: 'Old Blance',
    purchase_bill: 'Purchase Bill',
    purchase_summary: 'Purchase summary',
    print_voucher: 'Print',
    sales_vat: 'Sales VAT',
    bill_vat: 'Purchase VAT',
    invoice_export: 'Create Invoice',
    add_expense_type: 'Add Expense Type',
    name_ar_en_required: 'Arabic/English type name required',
    add_direct_invoice: 'Add direct invoice',
    car_ifnormation_required: 'Car information required',
    save_n_print: 'Save And Print',
    print_in_voucher: 'Print',
    chose_customer_type: 'Chose Custoemr Type',
    chose_invoice_type: 'Chose Invoice Type',
    chose_vat_type: 'Chose VAT Type',
    chose_payment_method: 'Chose Payment Type',
    chose_saler_name: 'Chose Saler name',
    total_without_vat: 'Total Ex. VAT',
    fftotal: 'Total inc. VAT', 
    changePassword: 'Change Password',
    newpassword: 'New Password',
    oldpassword: 'Old Password',
    printing_header: 'Printing Header',
    invoice_body: 'Invoice Body',
    voucher_body: 'Voucher Body',
    card_body: 'Maint Card Body',
    printing_footer: 'Printing Footer',
    car_status_receive: 'Car receive status',
    add_attachement: 'Add Attachments',
    government_clinet: 'Government',
    view_page: 'Page Preview',
    return_invoices: 'Return Invocies',
    vat_summary: 'VAT Summary',
    // fftotal: 'Total Inc. VAT',
    add_edit_product: 'Add/Edit Product',
    current_qtty: 'Current Qtty',
    price_without_vat: 'Price Ex. VAT',
    price_inc_vat: 'Price Inc. VAT',
    default_cost: 'Default Cost',
    added_value_tax: 'Enable Added Value Tax',
    product_category: 'Product Category',
    product_type: 'Product Type',
    inventory_item: 'Inventory Item',
    service_item: 'Service Item',
    this_item_service_item: 'Only inventory item type can be add to purchase bill',
    day: 'Day',
    print_out_voucher: 'Print Voucher',
    expenses_type_reoprt: 'Expenses Type Report',
    view_quotation: 'View Quotation',
    view_expenses: 'View Expenses Invoice',
    customer_company_info_required: 'Company Name and address are required',
    for_more_information_or_support_firstline: 'For support, or any inquires please contat us',
    for_more_information_or_support_secondline: ' 0595907777 ',
    whatsapp: 'WhatsApp',
    add_invoice_notes: 'Add notes to Invoice (warranty - Terms, etc.)', 
    new_customer: 'New Customer',
    entity_name: 'Entity Name',
    branch_name: 'Branch Name',
    request_number: 'Requist Number',
    order_number: 'Order Number',
    po_number: 'Request Number',
    po_date: 'Request Date',
    add_car: 'Add new car',
    footer_extra: "Footer Extend",
    view_quot_pdf: 'Download ',
    bid_number: 'Bid Number',
    expenses_invoices: 'Expenses Invocie',
    chose_car: 'Chose a Car',
    total_b_d: 'Total Befor Discount',
    total_discount: 'Discount',
    cars_list: 'Cars List',
    out_payments: 'Out Voutcher',
    in_payments: 'In Voutcher',
    changeNote: 'Modify Note',
    are_you_sure: 'Are you sure?',
    add_invocie: 'Add invoice',
    add_new_card: 'Add Job Order',
    cars_status: 'Cars Status',
    reset: 'Reset',
    undo: 'Undo',
    chose_customer: 'Chose Customer',
    chose_company: 'Chose Company',
    chose_entity: 'Chose Entity',
    company_name_required: 'Company name required',
    customer_name_required: 'Customer name required',
    company_address_required: 'Company address required',
    entity_name_required: 'Entity name required',
    car_information_must_be_complated: 'Car information for car plate {{plate_number}} required',
    you_must_add_one_car_atleast: 'You must add one car at least',
    you_must_add_atleast_one_work_item: 'You must add one item to work job',
    plase_select_car_scratch: 'You can not add empty image',
    chose_car_before_continue: 'You must select car to continue',
    approve_card: 'Approve Card',
    isbilled: 'is Billed',
    complete: 'Complete',
    add_new_work_expenses: 'Add new work/Sparepart',
    row_type: 'Row Type',
    agree: 'Agree',
    details: 'Details',
    time: 'Time',
    payment_type: 'Payment Type',
    amount: 'Amount',
    debet: 'Debet',
    daily_summary_for_date: 'Daily Summary for date',
    today_balance: 'Day\' Balance',
    previews_balance: 'Previews Balance',
    total_balance: 'Total Balance',
    purchase_and_expenses:'Purchase & Expese',
    credit_note:'Credit Notes',
    period_balance: 'Period Balance',
    add_vendor_voucher: 'Exchange Vouch.',
    salary_paid: 'Salary Payment',
    client_statment: 'Client Statement',
    file_size: 'File size',
    file_name: 'File name',
    download: 'Download',
    cancel_order: 'Cancel Order',
    add_order: 'Add order',
    view_order: 'View order',
    orderid: 'Order ID',
    order_date: 'Order date',
    order_time: 'Order Time',
    order_details: 'Order Details',
    order_items: 'Order Items',
    cancel_qtty: 'Canceled Qtty',
    cancel_reson: 'Canceled Reson',
    full_cancel_reson: 'Full cancel resone',
    full_cancel: 'Full Cancel',
    partial_cancel: 'Partial cancel',
    custody: 'Custody',
    return_custody: 'Paid From Custody',
    remain_custody: 'Remain From Custody',
    add_custody: 'Add Custody',
    width: 'Width',
    thickness: 'Thickness',
    limited: 'Limited',
    customer_sign: 'Signature',
    back: 'Back',
    terms_and_conditions: 'Terms and conditions',
    work_hours: 'Working hours',
    vendor_payments: 'Vendor Payments',
    terms: 'Terms',
    pay_from_custdy: 'Pay from custdy',
    pay_from_cash: 'Pay from cash',
    emp_statment: 'Employee Statement',
    deduct_from_salary: 'Salary Deduct',
    pay_advanced: 'Pay Advanced',
    add_reward: 'Add rewared',
    post_salary: 'Post Salary',
    indemnity: 'Indemnity',
    posted_salary: 'Posted Salary',
    card_percentage:'Card Percentage',
    invoice_percentage: 'Invoice Percentage',
    remain_salary: 'Remain Salary',
    lated_salaries: 'Rest Salary',
    salary_total: 'Salary Total',
    custdy: 'Custdy',
    mentanace_card: 'Mentanance Cards',
    service: 'Services',
    deduct: 'deduct',
    paid_in_advanced_total: 'Paid Advanced Ttoal',
    number_of_parts: 'Number of Parts',
    part_amount: 'Part Amount',
    returned: 'Retrurend',
    deduct_resone: 'Deduct Resone',
    minute_late: 'Minute Late',
    deduct_type: 'Deduct type',
    monthly_late: 'Monthly late',
    deduct_amount: 'Deduct Amount',
    monthly_deduct: 'Deduct Monthly Amount',
    fix_it: 'Fix',
    today_date: 'Today Date',
    search_by_name: 'Search By Name',
    daily_report: 'Daily Report',
    max_items_sales: 'Most Sales',
    less_items_sates: 'Less Sales',
    zero_items: 'Zero Items',
    sales_qtty: 'Sales QTY',
    total_income: 'Total Income',
    current_qty: 'Current QTY',
    remainpso: 'Remain',
    open_shift: 'Sift Start',
    _total_1_ryals: '1 SAR',
    _total_5_ryals: '5 SAR',
    _total_10_ryals: '10 SAR',
    _total_20_ryals: '20 SAR',
    _total_50_ryals: '50 SAR',
    _total_100_ryals: '100 SAR',
    _total_500_ryals: '500 SAR',
    opening_balance: 'Opening Balance',
    open_new_shift: 'Open shift',
    calendar: 'Calendar',
    add_purchase: 'Add Purchase',
    clode_shift: 'Close Shift',
    total_payment_to_vendor: 'Remain vendor payments is: ',
    total_payment_to_Client: 'Remain Client payments is: ',
    client_statement_pos: 'Client Statement',
    client_appointment: 'Client Calendar',
    add_client_voucher: 'Add Client Payment',
    invoice_summary: 'Invoice Summary',
    total_after_discountt: 'Price After Discount',
    cashair: 'Cashair',
    managment: 'Management',
    smiller_products:'Smiller products',
    emp_custody: 'Custody',
    menu: 'Mentu',
    book_tables: 'Book Table',
    add_your_cash: 'Add your shift cash',
    mobile_info_required: 'Client mobile required and must more than 4 digits',
    mobile_info_required_fullmobile: 'Client mobile required and must start with 05 and more than 5 digits',
    name_info_required: 'Client name required and must more than 5 chars',
    view_customer: 'View Customer',
    add_fincance_request: 'Add F-Request',
    cars: 'Cars',
    add_notes: 'Add Notes',
    finance_report: 'Finance Report',
    unpaid_job_orders: 'Not Invoiced',
    report: 'Report',
    financial_request: 'Financial Requests',
    tasks: 'Tasks',
    add_task: 'Add Task',
    task_description: 'Task Description',
    dead_line: 'Dead Line',
    task_to: 'Task to User',
    task_description_required: 'Task description required',
    task_start_end_date_required: 'Task start & deadline required',
    start_time_required: 'Calendar start time & deadline time required',
    last_update_by: 'Updated By',
    last_update_date: 'Updated Date',
    closed_date: 'Closed Date',
    add_warantly: 'Warantly Job',
    plate_number_required: 'Plate number required',
    add_booking: 'Booking',
    start_time: 'Start time',
    deadline_time: 'Deadline time',
    book_calendar: 'Calendar',
    tasks_status: 'Tasks',
    warantly_status: 'Waratnly',
    calendar_status: 'Calendar',
    customer_status: 'Customer Stauts',
    ment_description: "Ment. Description",
    add_new_spare_part: 'Add Spare part',
    // item_name: 'Spare part name',
    spare_part_item_code: 'Spare part item code',
    add_spare_part: 'Add sparepart',
    work_type: 'Work Type',
    carsnumber: 'Cars Count',
    add_new_status: 'Add New Status',
    vin_numebr: 'VIN',
    brand_name: 'Car Make',
    ready_to_deliver: 'Ready',
    cancel_card: 'Cancel',
    vin_number: 'VIN',
    calendar_table: 'Calendar Table',
    choose_custoemr: 'Select Client',
    estimated_price: 'Estimated Price',
    work_name: 'Work Name',
    booking_procedure: 'Booking procedure',
    change_booking_date: 'Change booking',
    edit_calendar: 'Edit Calendar',
    expire_date: 'Expire date',
    note_date: 'Note date',
    added_by: 'Added by',
    task_type: 'task Type',
    training: 'Training',
    job_order: 'Job Order',
    last_update: 'Last update',
    last_action: 'Last Action',
    next_action: 'Next Action',
    next_action_date: 'Action Date',
    agent: 'Agent',
    view_crm: 'View CRM',
    generate_license: 'Gen. License',
    note_text: 'Note Text',
    note_type: 'Note Type',
    task_response: 'Response',
    license_url: 'License URL',
    copies: 'Number of License',
    include_computers: 'Include Computers',
    yes_19: 'Yes include 19 Inch',
    yes_24: 'Yes include 24 Inch',
    no_devices: 'No',
    last_action_date: 'Last Action Date',
    whats_type: 'Whats MSG type',
    whats_url_multi: 'Doc, Image, Vedio URL',
    update_task: 'Update Task',
    industrial: 'Industrial',
    support_ticket: 'Support',
    add_support: 'Add Support',
    send_date: 'Sent date',
    message_type: 'Message Type',
    message_text: 'Message Text',
    customer_number: 'Customers Number',
    sending_number: 'Sending Number',
    media_link: 'Media Link',
    send_whatsapp: 'Send WhatsApp',
    send_to: 'Sent To',
    sent_type: 'Sent Type',
    number_list: 'Numbers List',
    number_list_notes: 'Separate numbers with comma',
    image_url: 'Image URL',
    document_url: 'Document URL',
    vedio_url: 'Vedio URL',
    message_title: 'Subject',
    client_type: 'Client Type',
    cannot_delete_this_customer_invoices_found: 'This customer had Invoices you can not delete it',
    cannot_delete_this_customer_cards_found: 'This customer had JOB CARDS you can not delete it',
    custoemr_delete_done: 'Customer Deleted Successfuly',
    cannot_delete_this_vendor_invoices_found: 'This vendor had Invoices you can not delete it',
    cannot_delete_this_vendor_payment_found: 'This vendor had Payments you can not delete it',
    vendor_delete_done: 'Vendor Deleted Successfuly',
    employee_deleted: 'Employee Deleted',
    advanced_payment_deleted: 'Advanced Payment Deleted',
    employee_name: 'Employee Name',
    number_of_tasks: 'Tasks #',
    closed_tasks: 'Closed',
    canceled_tasks: 'Canceled',
    opened_tasks: 'Open',
    add_work_order: 'Add Work Order',
    owner_name: 'Car Owner Name',
    companies: 'Companies',
    exeute_date: 'Execute Date',
    task_priority: 'Task Priority',
    day_name: 'Day Name',
    industry: 'Industry',
    support: 'Support',
    project_type: 'Project Type',
    project_title: 'Project Title',
    project_owner: 'Project Owner',
    project_start: 'Project Start',
    project_deadline: 'Project Start',
    project_close_date: 'Project Closed',
    project_employees: 'Project Employees',
    employee: 'Employees',
    task: 'Task',
    task_title: 'Task Title',
    checklist: 'Check List',
    superVisor: 'Supervisor',
    super_visor_required: 'Supervisor required',
    mobile_required: 'Mobile required',
    new_sales: 'New Sales',
    return_sales: 'Return Sales',
    card_type: 'Card Type',
    add_store: 'Add Store',
    store_name_ar: 'Store Name AR',
    store_name_en: 'Store Name EN',
    account_number: 'Account Number',
    trans_from: 'Transfer From',
    trans_to: 'Transfer To',
    trans_type: 'Transfer Type',
    received_by: 'Received By',
    add_transfer: 'Add Transfer',
    add_sinventory: 'Add Inventory',
    // inventory_item: 'Add Inventory',
    start_qtty: 'Start QTTY',
    cost_price: 'Cost Price',
    add_contract: 'Add Contract',
    contract_id: 'Contract ID',
    last_maint_date: 'Last Maint Date',
    last_maint_by: 'Last Maint. By',
    cars_number: 'Cars Number',
    contract_date: 'Contract Date',
    contract_expire_date: 'Contract Expired On',
    contract_start_date: 'Contract Start Date',
    meter_car: 'Car Meter',
    supports: 'Supports',
    contracts: 'Contracts',
    job_cards: 'Job Card',
    expected_income: 'Expected Income',
    title: 'Title',
    old_invoice: 'Old Invoice',
    unitId: 'Unit Id',
    main_unit_ar: 'Arabic Main Unit name',
    main_unit_en: 'English Main Unit name',
    add_unit: 'Add Unit',
    update_units: 'Update Unit',
    unit_type: 'Unit Type',
    main_unit: 'Main Unit',
    vendor_code: 'Vendor Code',
    is_finalProduct: 'Item Type',
    saleable: 'Saleable',
    update_procedures: 'Update Procedures',
    procedure: 'Procedure',
    erocedure_date: 'Procedure Date',
    client_priority: 'Priority',
    web_url: 'Web URL',
    purchase_inv: 'Purchase Inv.',
    api_token: 'WhatApp API Token To get This code Please Call (0595907777)',
    send_attachment: 'Send Attachment',
    PublishMe: 'Generate License',
    client_source: 'Client Source',
    add_offer: 'Add Offer',
    offer_name: '',
    offer_start: '',
    offer_end: '',
    add_new: 'Add New',
    campaign_start: 'Campaign Start',
    campaign_start_time: 'Campaign Start Time',
    campaign_title: 'Campaign Title',
    what_to_send: 'What To Send',
    send: 'Send',
    working_time_star: 'Working Start',
    working_time_end: 'Working End',
    product: 'Product',
    last_10_messages: 'Last 10 Messages',
    executed_date: 'Executed Date',
    support_case: 'Support Case',
    support_procedure: 'Support Procedure',
    source: 'Source',
    service_name: 'Service Name',
    available_times: 'Available Times',
    support_type: 'Support Type',
    department: 'Department',
    customer_require: 'customer required',
    subject_require: 'subject required',
    start_date_require: 'start date required',
    executed_date_require: 'executed date required',
    deadline_require: 'deadline required',
    times_require: 'Please select 1 available time at least',
    notes_require: 'notes required',
    alert: "alert",
    error_no_qty: "This item not exist or there is no qtty, please add it throw purcahse invoice",
    mobile_alert_10: 'Mobile number must start with 05 and 10 digit',
    all_calr_plate_field_required: 'All Fields required',
    car_digit_4: 'Car plate digits 4 numbers',
    pay_more_than_price: 'Can not pay more than total of invoice',
    all_field_require: 'All fields required',
    date_out_of_day: 'You can not chose date more than today',
    date_required: 'Date required',
    empty_invoice_error: 'You can not add empty invoice',
    payment_method_error: 'Chose payment method',
    custoday_not_equal: 'Employee custoday not complete payment',
    call: 'Call',
    branches: 'Branches',
    taxes: 'taxes',
    stores: 'stores',
    successfuly_job: 'Job done',
    renewal_amount: 'Renewal Amount',
    renew: 'Renew',
    years: 'Years',
    temporary_expenses: 'Temp. Expenses',
    select_store_before_continue: 'Store Required',
    please_add_one_item_before_continue: 'Please add atleast 1 item to continue',
    company_name_and_company_address_required: 'Company name and address required',
    carstotal: 'Cars Total',
    item_totals: 'Items Total',
    created_by: 'Created By',
    card_supervisor: 'Card Tech',
    technical: 'Technical',
    brand: 'Brnad',
    color: 'Color',
    vin: 'VIN',
    visit_oursite: 'Visit our Site',
    from_store: 'From Store',
    to_store: 'To Store',
    available_qtty: 'Avaiable QTY',
    transfer_qtty: 'Transfer QTY',
    costs: 'Costs',
    wins: 'Total Profit',
    profit: 'Profit',
    unit_namear: 'Unint Arabic name',
    unit_nameen: 'Unint English name',
    no_account_en: 'No account',
    product_namear: "Product Arabic name",
    product_nameen: "Product English name",
    is_saleable: 'Is this product saleable',
    card_paid: "Paid",
    card_not_paid: "Not Paid",
    card_paid_status: 'Payment Status',
    note_text_required: 'Note text required',
    sub_accounts: 'Sub Accounts',
    add_sub_account: 'Add Sub Accounts',
    balance_type: 'Balanced',
    bnl_type: 'Profet & Lost',
    income_type: 'Income',
    other_type: 'Other',
    analyses_account:'Analyses',
    main_account: 'Main',
    debit: 'Debit',
    gov_expenses: 'Government Expenses',
    update_journal:'Update Journal',
    add_new_journal: 'Add new Journal',
    error_date: 'Please chose date inside your Finance year',
    add_fixed_assets: 'Add Fixed Assets',
    udpate_fixed_assets: 'Update Fixed Assets',
    purchase_date: 'Purcahse Date',
    purchase_price: 'Purcahse Price',
    depreciation_rate: 'Depreciation Rate',
    depreciation_period: 'Depreciation Period',
    depreciation_amount: 'Depreciation Amount',
    current_price: 'Current Price',
    fixed_assets_namear: 'Fixes assets Arabic Name',
    fixed_assets_nameen: 'Fixed assets English Name',
    plase_add_assets_ar_name: 'Please add assets arabic name',
    plase_add_assets_en_name: 'Please add assets english name',
    please_add_purchase_price: 'Plase add Purchase Price',
    please_add_purchase_date: 'Plase add Purchase Date',
    please_add_qtty: 'Please add qtty',
    add_out_voucher: 'Add Voucher',
    edit_voucher: 'Update Voucher',
    delete_invoice: 'Delete Invoice',
    return_selected: 'Return Selected',
    delete_selected: 'Delete Selected',
    final_product: 'Final Product',
    raw_material: 'Raw Material',
    factory_item: 'Factory Item',
    salesable: 'Salesable',
    none_salesable: 'None Salesable',
    unit_name: 'Unit Name',
    sale_price: 'Sale Price',
    minimum_salse_price: 'Min Sale Price',
    maximum_salse_price: 'Max Sale Price',
    finger_print_id: 'Finger Print ID',
    employee_name_required: 'Employee name required',
    mobile_number_required: 'Mobile number require',
    base_salary_required: 'Base salary require',
    update_add_employee_success: "Update/Add employee successfuly",
    select_month: 'Select Month',
    select_year: 'Select year',
    custody_amount: 'Custoday Amount',
    attend_late: 'Attend Late',
    left_early: 'Left Early',
    not_attend: 'Not Attend',
    trial_in_balances: 'Trail in balances',
    trial_in_totals: 'Trial in totals',
    trial_balance : 'Trial Balance',
    hide_zero_accounts: 'Hide zero accounts',
    pnl_report: 'Profit and Lost Report',
    this_item_used_you_can_not_delete_it: 'This items used, you can not delte it',
    deleted_successfuly: 'Delteted Successfuly',
    parent_classid: 'Parent Class',
    parent_class_name: 'Parent Name',
    coa_report_with_totals: 'COA Report with Totals',
    coa_report: 'COA Report',
    inventory_report: 'Inventory Report',
    select_store: 'Select Store',
    add_periodic_maint: 'Add Periodc Maint',
    last_visit: 'Last Visit',
    visit_type: 'Visit Type',
    add_vacation: 'Add Vacation',
    number_of_days: 'Number of days',
    period_maint: 'Period Maint.',
    maint_prog: 'Maint. Programs',
    maint_contract: 'Maint. Contracts',
    updatePeriodMaint: 'Update Period',
    add_title: 'Ad Title',
    title_cond: 'Title',
    campaign_price: 'Campaign Price',
    return_quotation: 'Reactive',
    FixedAssets: 'Fixed Assets',
    all_regions: 'All Cities',
    exact_city: 'Exact City',
    campaign_area: 'Campaign Area',
    vehicle_type: 'Vehicle Type',
    campaign_end: 'campaign end',
    campaign_branches: 'Campaign Branches',
    campaign_target: 'Campaign Target',
    campaign_cost: 'Campaign Cost',
    campaign_clients: 'Campaign Clients',
    campaign_profit: 'Campaign Profit',
    created_date: 'Created Date',
    all_branches: 'All Branches',
    view_offer: 'View Offer',
    view_subscriber: 'View Subscriber',
    customer_afforded: 'Customer Afforded',
    car_digi_4: 'Car Plate Number must be 4 digits',
    afforded_customer_name: 'Afforded Client',
    sales_total: 'Sales Total',
    sales_ftotal: 'Sales Total With VAT',
    all_total: 'Requested Total',
    customer_support: 'Customer Support',
    calnder_all_field_required: 'Calender Fields are required',
    vacation_reasons: 'Vacation Reason',
    vacation_remains: 'Vacation Remain',
    vacation_start_date_required: 'Vacation start date required',
    vacation_reasons_required: 'Vacation reasons required',
    your_vacation_limit_is_overdue: 'Your Vacationd Days is over limit',
    vacations: 'vacations',
    refresh: 'refresh',
    chose_from_list: 'Chose',
    addes_successfuly: 'Added Successfuly',
    process_job_list: 'Procead Job Order',
    closed_job_list: "Closed Job Order",
    not_invoice_job_list: "Not Invoiced Job Order",
    invoiced_job_list: "Invoiced Job Order",
    current_paid_job_list: "Current Paid Job List",
    cashflow: "Cashflow",
    choose_mentainance_date: 'Choose Maintenance Date',
    maintenance_time: 'Maintenance Time',
    offers_programs: 'Offer Programs',
    program_name: 'Program name',
    booking_date: 'Booking Date',
    booking_time: 'Booking Time',
    contract_details: 'Contract Details',
    work_order: 'Work Order',
    print_job_order: 'Print job order',
    work_total_price: 'Work total',
    other_profit_total :'Other profit total',
    all_: 'All ',
    chose_store: 'Chose Warehouse',
    cost_center_name_ar: 'Cost Center Arabic',
    cost_center_name_en: 'Cost Center English',
    costCenter: 'Cost Center',
    addCostCenter: 'Add Cost Center',
    without_cost_center: 'Without Cost Center',
    account_client_type: 'Client Account Type',
    main_clients: 'Main Account',
    without_account: 'Customer Account',
    depreciation_account: 'Depreciation Account',
    quarter: 'Quarter',
    semiannually: 'Semiannually',
    annually: 'Annually',
    delivery_note: 'Delivery Note',
    consumption_rate: 'Discount Per',
    consumption_fees: 'Consumption fees',
    consumption_value: 'Consumption fees',
    add_check: 'Add Check',
    checkup_type: 'Checkup Type',
    checkup_number: 'Checkup Number',
    insurance_company: 'Insurance Company',
    delivered_by: 'Delivered By',
    car_received_status: 'Car Received Status',
    maintenance_required: 'Maintenance Required',
    direction: 'Direction',
    description_required: 'الوصف مطلوب ولا يمكن ان يكون فارغا',
    insurance_company_required: 'يجب اضافة شركة التأمين',
    customer_required: 'يجب ان تقوم باضافة عميل',
    car_model_required: 'يجب اضافة نوع السيارة',
    car_brand_required: 'يجب اضافة الشركة الصانعة للسيارة',
    car_color_required: 'لون السيارة مطلوب',
    please_add_one_car_received_status: 'يجب اضافة حالة واحده للسيارة على الاقل',
    please_add_one_car_maintenance: 'يجب اضافة طلب اصلاح واحد على الاقل',
    checkup_id: 'معرف استلام السيارة',
    owner_mobile_number: 'Owner Mobile',
  }


  export default enLang;